<template>
    <NavBar/>
    <div class=" justify-center mt-5 px-10">
        <div class="mb-12 flex justify-center">
            <h2 class="text-5xl font-redHat font-bold">Perbaharui Jenis Penjualan</h2>
        </div>
        <form @submit.prevent="insert">
            <div class="mb-20 px-6">
                <div class="mx-10">
                        <label for="jenis-penjualan" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Jenis Penjualan</label>
                        <input type="text" v-model="currentData.name" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="jenis-penjualan" placeholder="">
                        
                </div>

                
                

                

            
            </div>
    
            <div class="mb-3 space-x-5 flex justify-end px-10 ">
                <RouterLink to="jenispenjualan" class="bg-white text-xl font-redHat text-black text-center py-2 px-4 rounded font-bold border border-[#5FBAE6] w-[240px]">Batal</RouterLink>
                <button type="submit" class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]" >Simpan</button>
            </div>
        </form>


    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from 'axios';
import { ErrorConnectionTimeOut, goPage,  ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom } from "@/assets/js/function";
import Cookies from "js-cookie";

export default {
    components: {
        NavBar,
    },

    data() {
        return {
            currentData: [
                // name: '',
            ],
            // newRecord: '',
        };
    },

    created() {
        // this.newRecord = JSON.parse(localStorage.getItem('record')) || [];
        const token = Cookies.get("token");
        this.config = {
        headers: {
            Authorization: `Bearer ` + token,
        },
        timeout: 30000,
        }

        //get data
        this.currentData = JSON.parse(localStorage.getItem('currentData'));
    },

    methods: {
        insert() {
            ShowLoading();
            let formData = new FormData();


            Object.entries(this.currentData).forEach(([key, value]) => {   // for looping in table
                formData.append(key, value);
            });

            

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'type_of_sales_update';
            } else {
                this.api = base_url + 'type_of_sales_update';
            }


            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    // console.log(response)
                    if (response.data.status == true) {
                        AlertBottom(response.data.message);
                        goPage("jenispenjualan");
                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
    },
}
</script>