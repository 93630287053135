import { createRouter, createWebHistory } from 'vue-router'
// import Cookies from "js-cookie";
// import HomeView from '../views/HomeView.vue'
// new pages
import SignIn from '@/views/pages/auth/SignIn.vue'
import DashBoard from '@/views/pages/DashBoard.vue'
import KategoriProduk from '@/views/pages/kategoriproduct/KategoriProduct.vue'
import AddKategoriProduk from '@/views/pages/kategoriproduct/AddKategoriProduk.vue'
import EditKategoriProduk from '@/views/pages/kategoriproduct/EditKategoriProduk.vue'
import ProdukData from '@/views/pages/produk/ProdukData.vue'
import AddProdukData from '@/views/pages/produk/AddProdukData.vue'
import EditProdukData from '@/views/pages/produk/EditProdukData.vue'
import AdminMd from '@/views/pages/admin/AdminMd.vue'
import AddAdminMd from '@/views/pages/admin/AddAdminMd.vue'
import EditAdminMd from '@/views/pages/admin/EditAdminMd.vue'
import LokasiPo from '@/views/pages/lokasi/LokasiPo.vue'
import AddLokasi from '@/views/pages/lokasi/AddLokasi.vue'
import EditLokasi from '@/views/pages/lokasi/EditLokasi.vue'
import SupplierMasterData from '@/views/pages/supplier/SupplierMasterdata.vue'
import AddSupplierMasterData from '@/views/pages/supplier/AddSupplierMasterdata.vue'
import EditSupplierMasterData from '@/views/pages/supplier/EditSupplierMasterdata.vue'
import JenisPenjualan from '@/views/pages/jenis-penjualan/JenisPenjualan.vue'
import AddJenisPenjualan from '@/views/pages/jenis-penjualan/AddJenisPenjualan.vue'
import EditJenisPenjualan from '@/views/pages/jenis-penjualan/EditJenisPenjualan.vue'
import StatusPesanan from '@/views/pages/status-pesanan/StatusPesanan.vue'
import AddStatuspesanan from '@/views/pages/status-pesanan/AddStatuspesanan.vue'
import EditStatuspesanan from '@/views/pages/status-pesanan/EditStatuspesanan.vue'
import CustomerView from '@/views/pages/customer/CustomerView.vue'
import AddCustomer from '@/views/pages/customer/AddCustomer.vue'
import EditCustomer from '@/views/pages/customer/EditCustomer.vue'
import PoView from '@/views/pages/po/PoView.vue'
import AddPo from '@/views/pages/po/AddPo.vue'
import EditPo from '@/views/pages/po/EditPo.vue'
import LiveIg from '@/views/pages/liveig/LiveIg.vue'
import PoBatch from '@/views/pages/pobatch/PoBatch.vue'
import CreateLiveIg from '@/views/pages/liveig/CreateLiveIg.vue'
import DataLiveIg from '@/views/pages/liveig/DataLiveIg.vue'
import CreatePoBatch from '@/views/pages/pobatch/CreatePoBatch.vue'
import ListOrder from '@/views/pages/listorder/ListOrder.vue'
import RekapDataIg from '@/views/pages/rekapdataliveig/RekapDataIg.vue'
import RekapLivepo from '@/views/pages/rekaplistorderan/RekapLivepo.vue'


const router = createRouter({
  history: createWebHistory("/"),

  linkActiveClass: "active",

  routes: [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: HomeView
    // },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (About.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import('../views/AboutView.vue')
    // },
    {
      path: '/',
      name: 'signin',
      component: SignIn,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: DashBoard,
      meta: { requiresAuth: true }
    },
    {
      path: '/kategoriproduk',
      name: 'kategoriproduk',
      component: KategoriProduk,
      meta: { requiresAuth: true }
    },
    {
      path: '/addkategoriproduk',
      name: 'addkategoriproduk',
      component: AddKategoriProduk,
      meta: { requiresAuth: true }
    },
    {
      path: '/editkategoriproduk',
      name: 'editkategoriproduk',
      component: EditKategoriProduk,
      meta: { requiresAuth: true }
    },
    {
      path: '/produk',
      name: 'produk',
      component: ProdukData,
      meta: { requiresAuth: true }
    },
    {
      path: '/addproduk',
      name: 'addproduk',
      component: AddProdukData,
      meta: { requiresAuth: true }
    },
    {
      path: '/editproduk',
      name: 'editproduk',
      component: EditProdukData,
      meta: { requiresAuth: true }
    },
    {
      path: '/admin',
      name: 'admin',
      component: AdminMd,
      meta: { requiresAuth: true }
    },
    {
      path: '/addadmin',
      name: 'addadmin',
      component: AddAdminMd,
      meta: { requiresAuth: true }
    },
    {
      path: '/editadmin',
      name: 'editadmin',
      component: EditAdminMd,
      meta: { requiresAuth: true }
    },
    {
      path: '/lokasipo',
      name: 'lokasipo',
      component: LokasiPo,
      meta: { requiresAuth: true }
    },
    {
      path: '/addlokasi',
      name: 'addlokasi',
      component: AddLokasi,
      meta: { requiresAuth: true }
    },
    {
      path: '/Editlokasipo',
      name: 'Editlokasipo',
      component: EditLokasi,
      meta: { requiresAuth: true }
    },
    {
      path: '/supplier',
      name: 'supplier',
      component: SupplierMasterData,
      meta: { requiresAuth: true }
    },
    {
      path: '/addsupplier',
      name: 'addsupplier',
      component: AddSupplierMasterData,
      meta: { requiresAuth: true }
    },
    {
      path: '/editsupplier',
      name: 'editsupplier',
      component: EditSupplierMasterData,
      meta: { requiresAuth: true }
    },
    {
      path: '/jenispenjualan',
      name: 'jenispenjualan',
      component: JenisPenjualan,
      meta: { requiresAuth: true }
    },
    {
      path: '/addjenispenjualan',
      name: 'addjenispenjualan',
      component: AddJenisPenjualan,
      meta: { requiresAuth: true }
    },
    {
      path: '/Editjenispenjualan',
      name: 'Editjenispenjualan',
      component: EditJenisPenjualan,
      meta: { requiresAuth: true }
    },
    {
      path: '/statuspesanan',
      name: 'statuspesanan',
      component: StatusPesanan,
      meta: { requiresAuth: true }
    },
    {
      path: '/addstatuspesanan',
      name: 'addstatuspesanan',
      component: AddStatuspesanan,
      meta: { requiresAuth: true }
    },
    {
      path: '/editstatuspesanan',
      name: 'editstatuspesanan',
      component: EditStatuspesanan,
      meta: { requiresAuth: true }
    },
    {
      path: '/customer',
      name: 'customer',
      component: CustomerView,
      meta: { requiresAuth: true }
    },
    {
      path: '/addcustomer',
      name: 'addcustomer',
      component: AddCustomer,
      meta: { requiresAuth: true }
    },
    {
      path: '/editcustomer',
      name: 'editcustomer',
      component: EditCustomer,
      meta: { requiresAuth: true }
    },
    {
      path: '/po',
      name: 'po',
      component: PoView,
      meta: { requiresAuth: true }
    },
    {
      path: '/addpo',
      name: 'addpo',
      component: AddPo,
      meta: { requiresAuth: true }
    },
    {
      path: '/editpo',
      name: 'editpo',
      component: EditPo,
      meta: { requiresAuth: true }
    },
    {
      path: '/liveig',
      name: 'liveig',
      component: LiveIg,
      meta: { requiresAuth: true }
    },
    {
      path: '/createliveig',
      name: 'createliveig',
      component: CreateLiveIg,
      meta: { requiresAuth: true }
    },
    {
      path: '/dataliveig',
      name: 'dataliveig',
      component: DataLiveIg,
      meta: { requiresAuth: true }
    },
    {
      path: '/pobatch',
      name: 'pobatch',
      component: PoBatch,
      meta: { requiresAuth: true }
    },
    {
      path: '/createpobatch',
      name: 'createpobatch',
      component: CreatePoBatch,
      meta: { requiresAuth: true }
    },
    {
      path: '/listorder',
      name: 'listorder',
      component: ListOrder,
      meta: { requiresAuth: true }
    },
    {
      path: '/rekapliveig',
      name: 'rekapliveig',
      component: RekapDataIg,
      meta: { requiresAuth: true }
    },
    {
      path: '/rekaplistpo',
      name: 'rekaplistpo',
      component: RekapLivepo,
      meta: { requiresAuth: true }
    },
  ]
});


// router.beforeEach((to, from, next) => {
//   const token = Cookies.get('token');  // Retrieve token from cookies

//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     // If the route requires authentication and there's no token
//     if (!token) {
//       next('signin');  // Redirect to login if not authenticated
//     } else if (to.matched.some((route) => route.meta.requiresAuth == false)) {
//       next(); 
//     }else {
//       next();  // Proceed to route
//     }
//   } else {
//     next('signin');  // Proceed to route if no auth required
//   }
// });

export default router
