<template>
  <div id="app">
    <RouterView />
  </div>

</template>

<style>
/* .bg-merah {
  background-color: red !important;
} */

.custom-td-left {
  border-top-left-radius: 10px ;
  border-bottom-left-radius: 10px;
  text-align: center;
  width: 5%;
  
}
.custom-td-right {
  border-top-right-radius: 10px ;
  border-bottom-right-radius: 10px;
  /* text-align: center; */
}

.tr-table {
  background-color: #f3f4f6; /* bg-gray-200 */
  /* border: 1px solid white; */
  border-bottom: 1px solid white; /* border-b */
}

.tr-table:hover {
  background-color: #e5e7eb; 
}

</style>



<script >
// import { RouterLink, RouterView } from 'vue-router'
// import HelloWorld from './components/HelloWorld.vue'




export default {


  mounted() {
      // Initialize DataTable after the DOM is rendered
      this.initializeDataTable();
  },

  created() {
    // Optionally clear obsolete or stale route data
    if (!this.$router.getRoutes().find(route => route.path === localStorage.getItem('previousRoute'))) {
      localStorage.removeItem('previousRoute');
    }
  },

  methods: {
    initializeDataTable() {
        // Ensure DataTable is initialized correctly
        // new DataTable('#example');
        window.$('#example').DataTable();

        window.$('.dt-search').addClass('flex justify-end items-center');
        window.$('select').addClass('mr-3');
        window.$('.dt-paging').addClass('flex justify-end');
        window.$('.dt-search label').addClass('mr-3');
        window.$('.dt-search label').addClass('hidden');
        window.$('.dt-search input').addClass('hidden');
        window.$('.dt-info').addClass('hidden');
        // window.$('.paginating').addClass('active:bg-blue-300');

        
    },
  }
}

</script>
