import './assets/main.css'
import './assets/main.css';

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/main.css';


// for vue-select-3
// import vSelect from "vue-select-3";
// import "vue-select-3/dist/vue-select.css";
import  'vue-multiselect/dist/vue-multiselect.css';
import 'izitoast/dist/css/iziToast.min.css';



// import 'boxicons'



const app = createApp(App)

app.use(router)
// app.component("v-select", vSelect);

app.mount('#app')
