<template>
    <NavBar/>
    <!-- <h1>Dashboard </h1> -->
     <header class="w-full bg-[#5FBAE6] h-[450px] px-10 pt-12">
        <div class="grid grid-cols-2">
          <div class="left-content ">
            <div class="flex items-center space-x-1 gap-2">
                <h1 class="text-xl text-black font-redHat mb-1">Dashboard</h1>
                <button class="text-gray-500 hover:text-gray-700" @click="pilihLokasiBatch()"><i class="bi bi-pencil-fill"></i></button>
            </div>
            <h1 class="text-5xl text-black font-redHat font-bold" @click="pilihLokasi">PO BANGKOK</h1>
            <h1 class="text-5xl text-black font-redHat font-bold mt-3">BATCH 1</h1>
          </div>

          <!-- button dashboard -->
          <div class="right-content justify-end pt-7">
            <!-- <h1>button</h1> -->
             <div class="flex justify-end">
               <div class="relative p-0 rounded-[20px] w-[425px] max-w-lg mb-3 ">
                   <input type="text" class="rounded-[20px] p-3 w-full" name="search" placeholder="Cari nama customer">
   
                   <button type="submit" class="absolute right-6 top-3">
                           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                               stroke="currentColor" class="w-6 h-6">
                               <path stroke-linecap="round" stroke-linejoin="round"
                                   d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                           </svg>
                   </button>
               </div>
             </div>
            <!-- <button class="border border-black max-w-[120px] "></button> -->

            <!-- button group -->
            <div class="flex flex-row gap-3 justify-end">
                <div class="">
                    <button @click="dropMenuLaporan = !dropMenuLaporan" class="relative w-[225px] max-h-[47px] py-2 px-2 flex justify-center items-center text-center  bg-black border border-[#F5CA40] rounded-[10px] text-[#F5CA40] font-medium font-redHat">
                      <i class="bi bi-box-arrow-in-down me-5"></i>
                      Laporan
                    </button>
                    <!-- dropdown menu -->
                      <ul v-if="dropMenuLaporan" class="absolute z-40 bg-[#F1EDEA] shadow-lg mt-2 rounded-lg w-56 space-y-2 p-1">
                          <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80  hover:rounded-lg">
                              <a href="rekapliveig">Rekap Data Live IG</a>
                          </li>
                          
                          <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80  hover:rounded-lg">
                              <a href="rekaplistpo">Rekap List Orderan</a>
                          </li>
                          
                      </ul>
                </div>







              <RouterLink to="liveig" class="w-[225px] max-h-[47px] py-2 px-3 flex justify-center items-center text-center  bg-[#F5CA40] rounded-[10px] text-black font-medium font-redHat">Live IG</RouterLink>
              <RouterLink to="listorder" class="w-[225px] max-h-[47px] py-2 px-3 flex justify-center items-center text-center  bg-[#F5CA40] rounded-[10px] text-black font-medium font-redHat">List Orderan</RouterLink>
            </div>

          </div>
        </div>
     </header>

     <!-- <DashboardCard></DashboardCard> -->
      
     <div class="relative h-64">

       <div class="absolute -top-40 bg-none h-screen w-full px-6 pt-10">
         <div class="card-title pl-8">
             <h5 class="font-bold text-black text-3xl font-redHat font-bold">Live Instagram</h5>
         </div>
         <div class="grid gap-4 lg:gap-8 md:grid-cols-3 p-8 ">
  
             <div class="relative p-6 rounded-2xl bg-[#F5CA40] shadow">
                 <div class="grid grid-cols-2 p-4">
                     <div class="icon-chart  text-center text-[32px] font-bold w-24 text-[#07975A] flex justify-center items-center">
                         <div class="">
                             <i class="bi bi-graph-up"></i>

                         </div>
                     </div>
                     <div class="space-y-0 ">
                         <div class="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500">
                             <!-- <span>Revenue</span> -->
                         </div>
     
                         <div class="text-4xl text-4xl font-redHat text-black font-bold">
                             24
                         </div>
     
     
                         <div class="flex items-center font-redHat space-x-1 rtl:space-x-reverse text-3xl font-bold text-black">
     
                             <h1>Customer</h1>
     
                             <!-- <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true">
                                 <path fill-rule="evenodd"
                                     d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
                                     clip-rule="evenodd"></path>
                             </svg> -->
                         </div>
                     </div>
                 </div>
             </div>
  
             <div class="relative p-6 rounded-2xl bg-[#F5CA40] shadow">
                 <div class="grid grid-cols-2 p-4">
                     <div class="icon-chart  text-center text-[32px] text-black font-bold w-24 flex items-center justify-center">
                        <div class="">
                             <i class="bi bi-graph-up"></i>

                        </div>
                     </div>
                     <div class="space-y-0 ">
                         <div class="flex items-center font-redHat space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500">
                             <!-- <span>Revenue</span> -->
                         </div>
     
                         <div class="text-4xl text-black font-redHat font-bold">
                             12
                         </div>
     
     
                         <div class="flex items-center font-redHat space-x-1 rtl:space-x-reverse text-3xl font-bold text-black">
     
                             <h1>Deposit</h1>
     
                             <!-- <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true">
                                 <path fill-rule="evenodd"
                                     d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
                                     clip-rule="evenodd"></path>
                             </svg> -->
                         </div>
                     </div>
                 </div>
  
             </div>
  
             <div class="relative p-6 rounded-2xl bg-[#F5CA40] shadow">
                 <div class="grid grid-cols-2 p-4">
                     <div class="icon-chart items-center text-center text-[32px] text-red-700 font-bold w-24 flex justify-center items-center">
                        <div class="">
                             <i class="bi bi-graph-up"></i>

                        </div>
                     </div>
                     <div class="space-y-0 ">
                         <div class="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500">
                             <!-- <span>Revenue</span> -->
                         </div>
     
                         <div class="text-4xl font-redHat text-black font-bold">
                             75
                         </div>
     
     
                         <div class="flex items-center font-redHat space-x-1 rtl:space-x-reverse text-3xl font-bold text-black">
     
                             <h1>Done</h1>
     
                             <!-- <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true">
                                 <path fill-rule="evenodd"
                                     d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
                                     clip-rule="evenodd"></path>
                             </svg> -->
                         </div>
                     </div>
                 </div>
             </div>
         </div>
  
         <!-- second section -->
         <div class="card-title pl-8">
             <h5 class="font-bold text-black text-3xl font-redHat font-bold ">Story Instagram</h5>
         </div>
         <div class="grid gap-4 lg:gap-8 md:grid-cols-3 p-8">
  
             <div class="relative p-6 rounded-2xl bg-[#F5CA40] shadow">
                 <div class="grid grid-cols-2 p-4">
                     <div class="icon-chart  text-center text-[32px] font-bold w-24 text-[#07975A] flex justify-center items-center">
                        <div class="">
                             <i class="bi bi-graph-up"></i>

                        </div>
                     </div>
                     <div class="space-y-0 ">
                         <div class="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500">
                             <!-- <span>Revenue</span> -->
                         </div>
     
                         <div class="text-4xl font-redHat text-black font-bold">
                             60
                         </div>
     
     
                         <div class="flex items-center space-x-1 rtl:space-x-reverse text-3xl font-redHat font-bold text-black">
     
                             <h1>Orderan</h1>
     
                             <!-- <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true">
                                 <path fill-rule="evenodd"
                                     d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
                                     clip-rule="evenodd"></path>
                             </svg> -->
                         </div>
                     </div>
                 </div>
             </div>
  
             <div class="relative p-6 rounded-2xl bg-[#F5CA40] shadow">
                 <div class="grid grid-cols-2 p-4">
                     <div class="icon-chart items-center text-center text-[32px] text-black font-bold w-24 flex justify-center items-center">
                        <div class="">
                             <i class="bi bi-graph-up"></i>

                        </div>
                     </div>
                     <div class="space-y-0 ">
                         <div class="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500">
                             <!-- <span>Revenue</span> -->
                         </div>
     
                         <div class="text-4xl font-redHat text-black font-bold">
                             15
                         </div>
     
     
                         <div class="flex items-center space-x-1 rtl:space-x-reverse text-2xl font-redHat font-bold text-black">
     
                             <h1>On Process</h1>
     
                             <!-- <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true">
                                 <path fill-rule="evenodd"
                                     d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
                                     clip-rule="evenodd"></path>
                             </svg> -->
                         </div>
                     </div>
                 </div>
  
             </div>
  
             <div class="relative p-6 rounded-2xl bg-[#F5CA40] shadow">
                 <div class="grid grid-cols-2 p-4">
                     <div class="icon-chart items-center text-center text-[32px] text-red-700 font-bold w-24 flex justify-center items-center">
                        <div class="">
                             <i class="bi bi-graph-up"></i>

                        </div>
                     </div>
                     <div class="space-y-0 ">
                         <div class="flex items-center space-x-2 rtl:space-x-reverse text-sm font-medium text-gray-500">
                             <!-- <span>Revenue</span> -->
                         </div>
     
                         <div class="text-4xl font-redHat text-black font-bold">
                             8
                         </div>
     
     
                         <div class="flex items-center space-x-1 rtl:space-x-reverse text-3xl font-redHat font-bold text-black">
     
                             <h1>Sold Out</h1>
     
                             <!-- <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                 aria-hidden="true">
                                 <path fill-rule="evenodd"
                                     d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
                                     clip-rule="evenodd"></path>
                             </svg> -->
                         </div>
                     </div>
                 </div>
             </div>
         </div>
       </div>
     </div>


     <!-- modal delete -->
     <div
        v-if="isModalOpen"
        class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <!-- Modal content -->
        <div
          class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full"
          @click.stop>
          <div class="flex justify-between items-center mb-4">
            <h2 class="text-xl font-semibold">Pilih PO Batch</h2>
            <button
              @click="closeModal"
              class="text-red-600 hover:text-gray-900">
              ✕
            </button>
          </div>
          <div class="">
            <div class="mb-3">
                <form class="w-full max-w-lg mx-auto">
                      <label for="countries" class="block mb-2 text-lg font-redHat font-medium text-gray-900 dark:text-white">PO ke</label>
                      <select id="countries" v-model="selectedStore" class="bg-gray-50 border border-gray-300  text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 font-redHat font-medium text-[#5FBAE6] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                          <option selected>-AUTO BATCH 1-</option>
                          <!-- <option v-for="(option, index) in stores" :key="index" :value="option.value">{{ option.name }}</option> -->
                          
                      </select>
                </form>
            </div>
            <div class="mb-3">
                <form class="w-full max-w-lg mx-auto">
                    <label for="countries" class="block mb-2 text-lg font-redHat font-medium text-gray-900 dark:text-white">Lokasi PO</label>
                    <select id="countries" v-model="selectedStore" class="bg-gray-50 border border-gray-300 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 font-redHat font-medium text-[#5FBAE6] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option disabled selected>BANGKOK</option>
                        <!-- <option v-for="(option, index) in stores" :key="index" :value="option.value">{{ option.name }}</option> -->
                        
                    </select>
                </form>
            </div>
            
          </div>
  
          <div class="space-y-3">
              <div aria-hidden="true" class="border-t px-2"></div>
              <div class="button-group flex justify-end">
                  <button
                    @click="closeModal"
                    class="bg-gray-400 text-white font-bold py-2 px-4 rounded hover:bg-gray-700 me-3">
                    Close
                  </button>
                  <button
                    @click="confirmDelete"
                    class="bg-[#F5CA40] text-white font-bold py-2 px-4 rounded">
                    Simpan
                  </button>
              </div>
          </div>
        </div>
      </div>


</template>

<style scoped>

</style>


<script>
import NavBar from '@/components/NavBar.vue';
// import DashboardCard from '@/components/DashboardCard.vue';


export default {
  components: {
    NavBar,
    // DashboardCard
  },

  data(){
    return {
        dropMenuLaporan: false,
        isModalOpen: false,
    }
  },

  methods: {
    pilihLokasiBatch() {
        // this.currentDeleteIndex = index;

        this.isModalOpen = true;
    },
    closeModal() {
        this.isModalOpen = false;
    },
    // confirmDelete() {
    //     // Handle the actual deletion here
    //     if (this.currentDeleteIndex !== null) {
    //         this.records.splice(this.currentDeleteIndex, 1);  // Delete the item
    //         this.closeModal();  // Close the modal after deletion
    //     }
    // },
  }
};
</script>