<template>
    <NavBar/>
    <div class=" justify-center mt-5 px-10">
        <div class="mb-12 flex justify-center">
            <h2 class="text-[50px] font-bold">Kategori Produk</h2>
        </div>
        <form @submit.prevent="insert">
            <div class="mb-20 px-10">
                <label  class="mb-3 block text-base font-normal text-gray-400">
                        Kategori Produk
                    </label>
                    <input type="text" v-model="currentData.name" id="kategoriProduk" placeholder=""
                        class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#F5CA40] focus:shadow-md" required/>
            </div>
    
            <div class="mb-3 space-x-5 flex justify-end px-10">
                <RouterLink to="kategoriproduk" class="bg-white text-[20px] text-black text-center py-2 px-4 rounded font-medium border border-[#5FBAE6] w-[240px]">Batal</RouterLink>
                <button type="submit" class="bg-[#F5CA40] text-black text-[20px] text-center py-2 px-4 rounded font-medium w-[240px]" >Simpan</button>
            </div>
        </form>


    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from 'axios';
import {ErrorConnectionTimeOut, goPage,  ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom } from "@/assets/js/function";
import Cookies from "js-cookie";


export default {
    components: {
        NavBar,
    },

    data() {
        return {
            currentData: [
                // name: '',
            ],
            // newRecord: '',
        };
    },

    created() {
        const token = Cookies.get("token");
        // console.log(token)
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }

        // console.log(this.config);
        // this.index();
    }, 

    methods: {
        insert() {
            ShowLoading();
            let formData = new FormData();

            Object.entries(this.currentData).forEach(([key, value]) => {
                formData.append(key, value);
            });


            // formData.delete("foto_profile");

            // if (this.$refs.foto_profile.value != "") {

            //     formData.append("foto_profile", this.gambar_profile_64);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'category_insert';
            } else {
                this.api = base_url + 'category_insert';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    // console.log(response)
                    if (response.data.status == true) {
                        AlertBottom(response.data.message);
                        goPage("kategoriproduk");
                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
    },
}
</script>