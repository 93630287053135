<template>
    <NavBar/>
    <div class=" justify-center mt-5 px-10">
        <div class="mb-12 flex justify-center">
            <h2 class="text-5xl font-redHat font-bold">Customer Baru</h2>
        </div>
        <form @submit.prevent="insert">
            <div class="mb-20 px-6 mx-3">
                <div class="grid grid-cols-3 gap-4">
                    <div class="">
                            <label for="status-order" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Instagram</label>
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="status-order" placeholder="">
                            
                    </div>
                    <div class="">
                            <label for="status-order" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Nama Customer</label>
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="status-order" placeholder="">
                            
                    </div>
                    <div class="">
                            <label for="status-order" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">No. Whatsapp</label>
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="status-order" placeholder="">
                            
                    </div>

                </div>
                
                <!-- tipe customer -->
                <div class="button-group grid grid-cols-2 mt-5 gap-4">
                    <div class="">
                            <label for="status-order" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Tipe Customer</label>
                            <button class="w-full text-center items-center border-1 rounded py-2"
                                :class="{ 'bg-[#F5CA40] text-white': selectedButton === 'Reguler' }"
                                @click="selectButton('Reguler')">Reguler</button>
                            
                            
                    </div>
                    <div class="">
                            <label for="status-order" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2"></label>
                            <button class="mt-4 w-full text-center items-center border-1 rounded py-2"
                                :class="{ 'bg-[#F5CA40] text-white': selectedButton === 'Reseller' }"
                                @click="selectButton('Reseller')">Reseller</button>
                            
                            
                    </div>
                    
                </div>


                
                <div class="grid grid-cols-12 mt-5">
                    <div class="col-span-12">
                            <label for="alamat" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Alamat</label>
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="alamat" placeholder="">
                            
                    </div>
                </div>

                <div class="grid grid-cols-4 gap-4 mt-5">
                    <div class="">
                            <label for="alamat" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Provinsi</label>
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="alamat" placeholder="">
                            
                    </div>
                    <div class="">
                            <label for="alamat" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kecamatan</label>
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="alamat" placeholder="">
                            
                    </div>
                    <div class="">
                            <label for="alamat" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kota/Kabupaten</label>
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="alamat" placeholder="">
                            
                    </div>
                    <div class="">
                            <label for="alamat" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kode Pos</label>
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="alamat" placeholder="">
                            
                    </div>
                </div>

                <div class="bg-[#F5F5F5] rounded-[8px] grid grid-cols-2 gap-4 px-4 py-4 mt-5">
                    <div class="">
                        <label for="alamat" class=" font-redHat text-[#8F8F8F] font-medium font-2xl mb-3">Deposit</label>
                        <div class="flex gap-3">
                            <input type="text" class=" w-[270px] border-1 border-[#5FBAE6] rounded-md p-2 " name="alamat" placeholder="Tanggal">
                            <input type="text" class=" w-[270px] border-1 border-[#5FBAE6] rounded-md p-2 " name="alamat" placeholder="Atas Nama">
                            <input type="text" class="w-[270px] border-1 border-[#5FBAE6] rounded-md p-2 " name="alamat" placeholder="Nama Bank">
                            <input type="text" class="w-[270px] border-1 border-[#5FBAE6] rounded-md p-2 " name="alamat" placeholder="Nama Bank">
                            <input type="text" class=" w-[270px] border-1 border-[#5FBAE6] rounded-md p-2" name="alamat" placeholder="Jumlah">
                        
                            <button class="text-[#5FBAE6F2] w-[37px] h-[38px]"><i class="bi bi-plus-circle"></i></button>
                            
                        </div>
                            
                    </div>
                    <!-- <div class="">
                        <label for="alamat" class="font-redHat text-[#8F8F8F] font-medium font-2xl"></label>
                            
                    </div>
                    <div class="">
                        <label for="alamat" class=" font-redHat text-[#8F8F8F] font-medium font-2xl"></label>
                            
                    </div>
                    <div class="">
                        <label for="alamat" class=" font-redHat text-[#8F8F8F] font-medium font-2xl"></label>
                            
                    </div> -->
                    <!-- <div class="">
                        <button class="text-[#5FBAE6F2] w-[87px] h-[88px]"><i class="bi bi-plus-circle"></i></button>
                    </div> -->
                </div>

                


                
                

                

            
            </div>
    
            <div class="mb-3 space-x-5 flex justify-end px-10 ">
                <button @click="goBack" class="bg-white text-xl font-redHat text-black text-center py-2 px-4 rounded font-bold border border-[#5FBAE6] w-[240px]">Batal</button>
                <button type="submit" class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]" >Simpan</button>
            </div>
        </form>


    </div>
</template>

<style scoped>
.selected {
    background-color: #F5CA40;
    color: white;
}
</style>



<script>
import NavBar from '@/components/NavBar.vue';


export default {
    components: {
        NavBar,
    },

    data() {
        return {
            newRecord: {
                status_pesanan: '',
            },
            // newRecord: '',
            selectedButton: null, // to track the selected button
        };
    },

    methods: {
        selectButton(button) {
            this.selectedButton = button; // Set the clicked button as selected
        },

        goBack() {
            // Retrieve the previous route from localStorage
            const previousRoute = localStorage.getItem('previousRoute');
            if (previousRoute) {
                // Navigate to the previous route
                this.$router.push(previousRoute);
                // Optionally clear the saved route
                localStorage.removeItem('previousRoute');
            } else {
                // Fallback route if no previous route is found
                this.$router.push({ name: 'dashboard' });
            }
        },

        insert() {
            // if (this.newRecord.kategori.trim() !== '') {
            //     let kategoriProduk = JSON.parse(localStorage.getItem('kategoriProduk')) || [];

            //     kategoriProduk.push({ kategori: this.newRecord });

            //     localStorage.setItem('kategoriProduk', JSON.stringify(kategoriProduk));
                // console.log(this.newRecord)  

            //     // Redirect to index page after adding the category
            //     this.$router.push({ name: 'kategoriproduk' });
            // }
        },
    },
}
</script>