<template>
    <NavBar/>
    <header class="lg:px-16 px-4 bg-[#5FBAE6] flex flex-wrap items-center py-3 shadow-md">
        <div class="flex-1 flex justify-between items-center">
            <a href="#" class="text-3xl font-redHat font-bold text-[#F5CA40]">Customer</a>
        </div>

        

        <div class="hidden md:flex md:items-center md:w-auto w-full" id="menu">
            <nav>
                <ul class="md:flex items-center justify-between text-base text-gray-700 pt-3 md:pt-0 gap-2">
                    <li>
                        <div class="relative p-0 rounded-[20px] w-[250px] max-w-lg">
                            <input type="text" class="rounded-[20px] p-3 w-full font-redHat" name="search" placeholder="Cari">
            
                            <button type="submit" class="absolute right-4 top-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                        stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                    </svg>
                            </button>
                        </div>
                    </li>
                    <li><button class="md:p-4 py-3 px-0 block flex items-center justify-center w-[258px] h-[50px] rounded-[20px] bg-[#50C996] font-redHat text-black px-6 py-2.5 font-semibold border border-[#F1EDEA]" @click="addCustomer">Tambah Baru</button></li>
                    
                </ul>
            </nav>
        </div>
    </header>

    <div class="px-5 pt-5 mb-2">
        <div class="flex flex-col">
            <div class="-m-1.5 overflow-x-auto">
                <div class="p-1.5 min-w-full inline-block align-middle">
                    <div class="border border-white rounded-lg divide-y divide-white ">
                        
                        <div class="overflow-hidden">
                            <table id="example" class="display" >
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th >Nama Instagram</th>
                                        <th >Nama Customer</th>
                                        <th >Whatsapp</th>
                                        <th >Deposit</th>
                                        <th >Provinsi</th>
                                        <th >Tipe Customer</th>
                                        <th >Aksi</th>
                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in record" :key="index" class="tr-table ">
                                        <td class="custom-td-left">
                                            
                                                {{ index + 1 }}
                                            
                                        </td>
                                        <td>{{ item.nama_instagram }}</td>
                                        <td>{{ item.nama_customer }}</td>
                                        <td>{{ item.whatsapp }}</td>
                                        <td>{{ item.deposit }}</td>
                                        <td>{{ item.provinsi }}</td>
                                        <td>{{ item.tipe_customer }}</td>
                                        
                                        <td class="custom-td-right">
                                            <RouterLink to="editcustomer" type="button" class="inline-flex items-center gap-x-2 text-lg font-semibold text-black hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none me-2">
                                                <i class="bi bi-pencil-fill"></i>
                                            </RouterLink>

                                            <button type="button"  @click="openModalDelete(index)" class="inline-flex items-center gap-x-2 text-lg font-semibold text-red-500 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                                                <i class="bi bi-trash"></i>
                                            </button>
                                        </td>
                                        <!-- <td>{{ item.position }}</td>
                                        <td>{{ item.office }}</td>
                                        <td>{{ item.age }}</td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- modal delete -->
    <div
      v-if="isModalOpen"
      class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <!-- Modal content -->
      <div
        class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full"
        @click.stop>
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-xl font-semibold">Delete customer</h2>
          <button
            @click="closeModal"
            class="text-red-600 hover:text-gray-900">
            ✕
          </button>
        </div>
        <p class="text-gray-700 mb-10">
          Are you sure wants to delete this {{ currentDeleteIndex + 1 }} ?
        </p>

        <div class="space-y-3">
            <div aria-hidden="true" class="border-t px-2"></div>
            <div class="button-group flex justify-end">
                <button
                  @click="closeModal"
                  class="bg-gray-400 text-white font-bold py-2 px-4 rounded hover:bg-gray-700 me-3">
                  Close
                </button>
                <button
                  @click="confirmDelete"
                  class="bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700">
                  Delete
                </button>
            </div>
        </div>
      </div>
    </div>

</template>

<script>
import NavBar from '@/components/NavBar.vue';

export default {
    components: {
        NavBar,
    },

    data() {
        return {
            record: [
                {
                    nama_instagram: "@sampleName",
                    nama_customer: "sample name",
                    whatsapp: "0812345678",
                    deposit: "Rp.500.000",
                    provinsi: "Sumatera",
                    tipe_customer: "Reguler",

                },
                {
                    nama_instagram: "@sampleName",
                    nama_customer: "sample name",
                    whatsapp: "0812345678",
                    deposit: "Rp.500.000",
                    provinsi: "Sumatera",
                    tipe_customer: "Reguler",

                },
                {
                    nama_instagram: "@sampleName",
                    nama_customer: "sample name",
                    whatsapp: "0812345678",
                    deposit: "Rp.500.000",
                    provinsi: "Sumatera",
                    tipe_customer: "Reguler",

                },
                {
                    nama_instagram: "@sampleName",
                    nama_customer: "sample name",
                    whatsapp: "0812345678",
                    deposit: "Rp.500.000",
                    provinsi: "Sumatera",
                    tipe_customer: "Reguler",

                },
                {
                    nama_instagram: "@sampleName",
                    nama_customer: "sample name",
                    whatsapp: "0812345678",
                    deposit: "Rp.500.000",
                    provinsi: "Sumatera",
                    tipe_customer: "Reguler",

                },
                
                
                
                
                   
                

            ],
            isModalOpen: false,
            currentDeleteIndex: null,
        }
    },

    mounted() {
        // Initialize DataTable after the DOM is rendered
        // this.initializeDataTable();
        this.$root.initializeDataTable(); // Call the global method
    },

    methods: {
        // initializeDataTable() {
        //     // Ensure DataTable is initialized correctly
        //     // new DataTable('#example');
        //     window.$('#example').DataTable();

        //     window.$('.dt-search').addClass('flex justify-end items-center');
        //     window.$('select').addClass('mr-3');
        //     window.$('.dt-paging').addClass('flex justify-end');
        //     window.$('.dt-search label').addClass('mr-3');
        //     window.$('.dt-search label').addClass('hidden');
        //     window.$('.dt-search input').addClass('hidden');
        //     // window.$('.paginating a').addClass('active:bg-blue-300');

            
        // },
        
        addCustomer() {
            // Save the current route to localStorage
            localStorage.setItem('previousRoute', this.$route.fullPath);
            // Navigate to the Add Customer page
            this.$router.push({ name: 'addcustomer' });
        },
        
        updateKategori(produk){
            localStorage.setItem('record', JSON.stringify(produk));
            // goPage("EditKategoriProduk");
            // this.$router.push({ name: 'EditKategoriProduk' });
        },

        openModalDelete(index) {
            this.currentDeleteIndex = index;

            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
        },
        confirmDelete() {
            // Handle the actual deletion here
            if (this.currentDeleteIndex !== null) {
                this.record.splice(this.currentDeleteIndex, 1);  // Delete the item
                this.closeModal();  // Close the modal after deletion
            }
        },
    },
}
</script>
