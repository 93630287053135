<template>
    
  
  
      <div class="bg-gray-500 h-screen w-full flex items-center justify-center py-8">
          <div class="max-w-lg w-[700px] rounded-lg bg-[#5FBAE6] h-[580px] flex flex-col items-center p-8">
              <!-- Heading -->
              <div class="block mb-14 mt-6">
                  <h5 class="font-redHat text-3xl font-medium text-[#F5CA40F2]">PO Batch Baru</h5>
              </div>
  
              <!-- Form -->
              <div class="block mb-11 w-full">
                  <form class="w-full max-w-lg mx-auto">
                      <label for="countries" class="block mb-2 text-lg font-redHat font-medium text-gray-900 dark:text-white">PO ke</label>
                      <select id="countries" v-model="selectedStore" class="bg-gray-50 border border-gray-300  text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 font-redHat font-medium text-[#5FBAE6] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                          <option selected>-AUTO BATCH 1-</option>
                          <!-- <option v-for="(option, index) in stores" :key="index" :value="option.value">{{ option.name }}</option> -->
                          
                      </select>
                  </form>
              </div>
              <div class="block mb-11 w-full">
                  <form class="w-full max-w-lg mx-auto">
                      <label for="countries" class="block mb-2 text-lg font-redHat font-medium text-gray-900 dark:text-white">Lokasi PO</label>
                      <select id="countries" v-model="selectedStore" class="bg-gray-50 border border-gray-300 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 font-redHat font-medium text-[#5FBAE6] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                          <option disabled selected>BANGKOK</option>
                          <!-- <option v-for="(option, index) in stores" :key="index" :value="option.value">{{ option.name }}</option> -->
                          
                      </select>
                  </form>
              </div>
              <div class="block mb-9 w-full ">
                <label for="countries" class="block mb-2 text-lg font-redHat font-medium text-gray-900 dark:text-white">Periode</label>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
                    <div class="relative flex items-center text-[#5FBAE6]">
                        <input
                        type="date"
                        v-model="date"
                        class="border border-gray-300 rounded-lg pl-3 pr-10 py-2 w-full focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                        />
                        <!-- <i class="bx bx-calendar absolute right-3 text-[#5FBAE6]"></i> -->
                    </div>
                    <div class="relative flex items-center text-[#5FBAE6]">
                        <input
                        type="date"
                        v-model="date"
                        class="border border-gray-300 rounded-lg pl-3 pr-10 py-2 w-full focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                        />
                        <!-- <i class="bx bx-calendar absolute right-3 "></i> -->
                    </div>
                </div>

              </div>

  
              <!-- Submit Button -->
              <div class="block">
                  <RouterLink to="dashboard" type="submit" class="text-black font-redHat font-bold text-lg flex items-center justify-center px-5 py-2 bg-[#F5CA40] rounded-md w-[450px]">Simpan</RouterLink>
              </div>
          </div>
      </div>
  
  </template>
  
  <script>
  // import  from 'vue-select'
  
   export default {
      components: {
          // 'v-select': vSelect,
      },
  
      data() {
          return {
              selectedStore: null,
              stores: [
                  { value: 'TOKO_A', name: 'Toko A' },
                  { value: 'TOKO_B', name: 'Toko B' },
                  { value: 'TOKO_C', name: 'Toko C' },
                  { value: 'TOKO_D', name: 'Toko D' },
              ],
              date: null,
          };
      },
  
  };
  </script>
  