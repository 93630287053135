<template>

  <div class="grid grid-cols-2 h-screen bg-[#5FBAE6]">
    <div class="left-side bg-[#5FBAE6] flex justify-center items-center py-4">

      <div class="flex flex-col items-center">
        <div class="top-logo-banner">
          <img src="@/assets/img/logo-hwang.png" alt="logo-hwang" class="h-[128px] w-[128px]">
        </div>
        <div class="banner-img">

          <img src="@/assets/img/login-img-edit.png" alt="banner-login" class="h-[465px] w-[812px]">
        </div>
      </div>
    </div>
    <div class="right-side   ">
      <div class="flex flex-col items-center  h-full pt-2 justify-center">
        <div class="w-full bg-[#F5CA40F2]/[0.95] mt-8 rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 ">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <div class="text-center mb-8 pt-6">
              <h1 class="text-sm  md:text-[50px] font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                login
              </h1>
            </div>
            <form class="" @submit.prevent="onSubmit">
              <div class="mb-3">
                <label for="username" class="block mb-2 text-lg font-redHat text-gray-900 ">Username</label>
                <input type="text" v-model="username" name="username" id="username"
                  class="bg-gray-50 border border-white text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 "
                  placeholder="" required="">

                <!-- <div class="invalid-feedback">{{ errors.email }}</div> -->
                <!-- <div class="emailshow text-danger" id="email"></div>
                            <div class="position-absolute me-3 end-0 top-0 h-100 d-flex align-items-center"
                            v-if="!(errors.email)">
                            <i class='bx bx-envelope text-secondary' alt="img"></i>
                            </div> -->
              </div>
              <div>
                <label for="password"
                  class="block mb-2 text-lg font-redHat  text-gray-900 dark:text-white">Password</label>
                <input type="password" v-model="password" name="password" id="password" placeholder=""
                  class="bg-gray-50 border border-white text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 mb-8"
                  required="">

              </div>

              <!-- for test only -->
              <!-- <RouterLink :to="{ name: 'dashboard' }">
                        </RouterLink> -->
              <div class="mb-8">
                <button type="submit"
                  class="w-full mb-6 text-black bg-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-[20px] text-sm px-5 py-2.5 text-center">Masuk</button>

              </div>

            </form>
            <div class="privacy-policy mb-8">
              <p class="text-center">By continuing, you are agree to our <br>
                <a href="#" class="font-bold hover:text-white">Privacy Policy</a>
              </p>
            </div>
            <div class="scbd-bottom flex items-center justify-center mt-8">
              <img src="@/assets/img/mini-scbd-logo.png" alt="scbd-mini" class="w-[96px] h-[22px]">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>


<script>
// import { ref } from "vue";
// import { useStore } from "vuex";
// import { Form, } from "vee-validate";
// import { router } from "../../../router";
// import VueRouter from "vue-router";
// import * as Yup from "yup";
// import { ref } from 'vue';
import axios from "axios";
// import Cookies from "js-cookie";
import Swal from 'sweetalert2';

import { goPage, ShowLoading, CloseLoading, base_url, ErrorConnectionTimeOut, AlertPopup } from "@/assets/js/function";
import Cookies from "js-cookie";

export default {

  data() {
    return {
      //   showPassword: false,
      password: null,
      username: "",
      //   useremailphone: "",
      //   showAdmin: true,
      //   showOwner: false,
      //   bodyAllowNotification: "",
      //   statusAllow: "",
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },


  created() {
    // const token = Cookies.get("token_refresh");
    this.config = {
      timeout: 30000,
    }
    // this.index();
  },

  methods: {
    // toggleShow() {
    //   this.showPassword = !this.showPassword;
    // },
    // setTipeLogin(status) {
    //   ShowLoading();
    //   localStorage.setItem('tipe_login', status); goPage('dashboard');
    // },


    onSubmit() {
      ShowLoading(); // Show a loading spinner or message

      // Set the API based on the environment

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'login';
      } else {
        this.api = base_url + 'login';
      }

      // Create FormData object
      let formData = new FormData();
      formData.append('username', this.username);
      formData.append('password', this.password);

      // Make a POST request to the login API
      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          console.log(response)
          // Check if login is successful
          if (response.data.status === true) {
            // Login successful, handle response data
            Swal.fire('Success', 'Login successful', 'success');

            // You can store token or user data here if needed
            // localStorage.setItem('token', response.data.token);
            Cookies.set("token", response.data.token);

            // Redirect to dashboard or another page
            goPage('dashboard');
            // this.$router.push({ name: 'dashboard' });


            CloseLoading(); // Close loading spinner
          } else if (response.data.status === false) {
            // Handle login failure
            CloseLoading(); // Close loading spinner
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          // Handle any errors such as network issues or server downtime
          CloseLoading(); // Close loading spinner
          ErrorConnectionTimeOut(error);
        });
    },


    showModal(modal_name) {
      this.$refs[modal_name].classList.add('show');
      this.$refs[modal_name].style.display = 'block';
    },
    hideModal(modal_name) {
      this.$refs[modal_name].classList.remove('show');
      this.$refs[modal_name].style.display = 'none';

    },

    // signUp() {

    //   window.open('https://arthaskyline.com/contact.html', "_blank");
    //   CloseLoading();

    // },

    // changePassword() {
    //   ShowLoading();
    //   let formData = new FormData();
    //   formData.append("phone", this.useremailphone);

    //   let api = "";
    //   if (process.env.NODE_ENV === "development") {
    //     api = base_url + 'send_otp';
    //   } else {
    //     api = base_url + 'send_otp';
    //   }
    //   axios
    //     .post(api, formData)
    //     .then((response) => {
    //       console.log(response.data);
    //       if (response.data.status == true) {
    //         Cookies.set("token", response.data.token);
    //         Cookies.set("useremailphone", this.useremailphone);
    //         goPage('dashboard');
    //       }

    //       if (response.data.status == false) {
    //         AlertPopup("error", "", response.data.message, 1500, false);
    //       }
    //     })
    //     .catch((error) => {

    //       ErrorConnectionTimeOut(error);
    //     });

    // }
  },


  mounted() {

    // if (Notification.permission === "default") {
    //   this.showModal('allowNotification');
    //   this.statusAllow = 'default';
    //   this.bodyAllowNotification = 'Please Allow Notification Before You login ';
    // } else if (Notification.permission === "denied") {
    //   this.showModal('allowNotification');
    //   this.statusAllow = 'denied';
    //   this.bodyAllowNotification = 'Your Notification is blocked, please change!';
    // }


  },

};

</script>