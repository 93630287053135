<template>
    <NavBar/>
    <div class=" justify-center mt-5 px-10">
        <div class="mb-12 flex justify-center">
            <h2 class="text-5xl font-redHat font-bold">Tambah Status Pesanan</h2>
        </div>
        <form @submit.prevent="insert">
            <div class="mb-20 px-6">
                <div class="mx-10">
                        <label for="status-order" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Status</label>
                        <input type="text" v-model="currentData.name" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="status-order" placeholder="">
                        
                </div>

                
                

                

            
            </div>
    
            <div class="mb-3 space-x-5 flex justify-end px-10 ">
                <RouterLink to="statuspesanan" class="bg-white text-xl font-redHat text-black text-center py-2 px-4 rounded font-bold border border-[#5FBAE6] w-[240px]">Batal</RouterLink>
                <button type="submit" class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]" >Simpan</button>
            </div>
        </form>


    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from 'axios';
import {ErrorConnectionTimeOut, goPage,  ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom } from "@/assets/js/function";
import Cookies from "js-cookie";


export default {
    components: {
        NavBar,
    },

    data() {
        return {
            currentData: [
                // name: '',
            ],
            // newRecord: '',
        };
    },

    created() {
        const token = Cookies.get("token");
        // console.log(token)
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }

        // console.log(this.config);
        // this.index();
    }, 

    methods: {
        insert() {
            ShowLoading();
            let formData = new FormData();

            Object.entries(this.currentData).forEach(([key, value]) => {
                formData.append(key, value);
            });


            // formData.delete("foto_profile");

            // if (this.$refs.foto_profile.value != "") {

            //     formData.append("foto_profile", this.gambar_profile_64);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'order_status_insert';
            } else {
                this.api = base_url + 'order_status_insert';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    // console.log(response)
                    if (response.data.status == true) {
                        AlertBottom(response.data.message);
                        goPage("statuspesanan");
                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
    },
}
</script>