<template>
    <NavBar/>
    <div class=" justify-center mt-5 px-10">
        <div class="mb-12 flex justify-center">
            <h2 class="text-5xl font-redHat font-bold">Perbaharui PO</h2>
        </div>
        <form @submit.prevent="insert">
            <div class="mb-20 px-6">
                <div class="mb-4">
                    <label for="no-po" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Nomor PO</label>
                    <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="no-po" placeholder="Auto" disabled>
                    
                </div>
                <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 mb-4 ">
                    <div class=" ">
                        <div class="w-100">

                            <label for="kode-produk" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Periode PO</label>
                            <div class="flex gap-3 items-center w-100">

                                <div class="w-1/2">
                                    
                                    <input type="date" name="date" id="date"
                                        class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                </div>
                                <div class="w-fill">
                                    -
                                </div>
                                <div class="w-1/2">
        
                                <!-- <label for="kode-produk" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Periode PO</label> -->
                                <input type="date" name="date" id="date"
                                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    <!-- <div class="mb-5 ">
                        <label for="date" class="mb-3 block text-base font-medium text-[#07074D]">
                            for
                        </label>
                        <input type="date" name="date" id="date"
                            class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                    </div> -->

                    
                    
                    
                   
                    
                    
                    
                </div>

                <div class="mb-3">
                    <label for="kode-produk" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Lokasi PO</label>
                    <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="kode-produk" placeholder="">
                </div>
                
                <!-- <div class="">
                    <label for="kode-produk" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Mata Uang</label>
                    <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="kode-produk" placeholder="">
                </div> -->

                <div class="mb-3">
                    <label for="dropdown" class="block font-redHat font-2xl font-medium text-[#8F8F8F]">Mata Uang</label>
                    <select id="dropdown" class="block w-full mt-1 border-1 border-[#5FBAE6] rounded-md p-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                        <option value="">Pilih mata uang</option>
                        <option value="option1">Rupiah</option>
                        <option value="option2">Bath</option>
                    </select>
                </div>

                

            
            </div>
    
            <div class="mb-3 space-x-5 flex justify-end px-10">
                <RouterLink to="po" class="bg-white text-xl font-redHat text-black text-center py-2 px-4 rounded font-bold border border-[#5FBAE6] w-[240px]">Batal</RouterLink>
                <button type="submit" class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]" >Simpan</button>
            </div>
        </form>


    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';


export default {
    components: {
        NavBar,
    },

    data() {
        return {
            newRecord: {
                kategori: '',
            },
            // newRecord: '',
        };
    },

    methods: {
        insert() {
            // if (this.newRecord.kategori.trim() !== '') {
            //     let kategoriProduk = JSON.parse(localStorage.getItem('kategoriProduk')) || [];

            //     kategoriProduk.push({ kategori: this.newRecord });

            //     localStorage.setItem('kategoriProduk', JSON.stringify(kategoriProduk));
                // console.log(this.newRecord)  

            //     // Redirect to index page after adding the category
            //     this.$router.push({ name: 'kategoriproduk' });
            // }
        },
    },
}
</script>