<template>
    <div class=" w-full h-screen bg-[#5FBAE6] flex flex-col items-center justify-center pt-5">
        <div class="block the-button mb-4 rounded-[14px] shadow">
            <RouterLink to="createliveig" class="text-black font-redHat font-bold text-lg flex items-center justify-center px-8 py-3 bg-[#F5CA40] rounded-[14px] w-[272px]">Buat Live Instagram</RouterLink>
        </div>
        <div class="block h-[350px] w-[500px] pt-5">
            <img src="@/assets/img/img-content.png" alt="bg-img">
        </div>
    </div>
</template>

<style scoped></style>

<script></script>