<template>
  <NavBar />
  <header class="lg:px-16 px-5 bg-white flex flex-wrap items-center py-3">
    <div class="flex-1 flex justify-between items-center">
      <a href="#" class="text-3xl font-redHat text-[#F5CA40] font-bold">Live Instagram</a>
    </div>

    <div class="hidden md:flex md:items-center md:w-auto w-full" id="menu">
      <nav>
        <ul class="md:flex items-center justify-between text-base text-gray-700 pt-3 md:pt-0 gap-1">
          <li>
            <!-- <div class="relative p-0 rounded-[20px] w-[250px] max-w-lg"> -->
              <!-- <input type="text" class="rounded-[20px] p-3 w-full" name="search" placeholder="Cari"> -->
            <!-- </div> -->
            <button
              class="md:p-4 py-3 block flex items-center justify-center w-[200px] h-[40px] rounded-md bg-[#50C996] text-black py-2.5 font-semibold"
            >
              Customer Baru
            </button>
          </li>
          <li>
            
            <button
              class="md:p-4 py-3 px-0 block flex items-center justify-center w-[100px] h-[40px] rounded-md bg-[#F5CA40] text-black px-6 py-2.5 font-semibold gap-2"
            >
              Filter By <i class="bi bi-funnel-fill"></i>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </header>

  <div class="px-5 pt-5 mb-2">
        <!-- <div class="flex flex-col">
            <div class=" overflow-x-auto">
                <div class="p-1.5 min-w-full inline-block align-middle">
                    <div class="">
                        
                        
                        
                    </div>
                </div>
            </div>
        </div> -->


        <div class="">
          <!-- for datatable -->
          <table id="example" class="display z-50">
              <thead>
                  <tr>
                    <th styles="width: 100px;">No.</th>
                    <th >Instagram</th>
                    <th >Kode Produk</th>
                    <th >Warna</th>
                    <th >Size</th>
                    <th >Qty</th>
                    <th >Harga Jual</th>
                    <th >Berat</th>
                    <th >Aksi</th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="tr-table" v-for="(item, index) in records" :key="item.id">
                    <td class="custom-td-left w-[50px]">{{ index + 1 }}</td>
                    <!--<td><input type="text" v-model="item.ig" /></td> -->
                    <td class="w-[250px]">
                      <div class="m-2">
                        <multiselect
                            v-model="item.ig"
                            :options="options"
                            :custom-label="nameWithLang"
                            placeholder=""
                            label="name"
                            track-by=""
                            :selectLabel="''"
                            :deselectLabel="''"
                            class="multiselect-full-width"
                          ></multiselect>
                      </div>
                    </td>
                    <td class="w-[200px]">
                      <div class="m-2">

                        <multiselect
                            v-model="item.kode_produk"
                            :options="options2"
                            :custom-label="nameWithLang"
                            placeholder=""
                            label="name"
                            track-by=""
                            :selectLabel="''"
                            :deselectLabel="''"
                            class=""
                          ></multiselect>
                      </div>
                    </td>
                    <td class="w-[200px]">
                      <div class="m-2">

                        <multiselect
                            v-model="item.warna"
                            :options="options3"
                            :custom-label="nameWithLang"
                            placeholder=""
                            label="name"
                            track-by=""
                            :selectLabel="''"
                            :deselectLabel="''"
                            class="me-2"
                          ></multiselect>
                      </div>
                    </td>
                    <td class="w-[100px]">
                      <div class="m-2">

                        <multiselect
                            v-model="item.size"
                            :options="options4"
                            :custom-label="nameWithLang"
                            placeholder=""
                            label="name"
                            track-by=""
                            :selectLabel="''"
                            :deselectLabel="''"
                            class="me-3"
                          ></multiselect>
                      </div>
                    </td>
                    <td class="w-[90px] py-0">
                      <div class="m-2">
                        <input type="number" v-model="item.qty" class="rounded h-[40px]  w-full border border-[#e8e8e8]" />
                      </div>
                    </td>
                    <td class="w-[160px]">
                      <div class="m-2">
                        <input type="text" v-model="item.harga_jual" class="rounded w-full  h-[40px] border border-[#e8e8e8]" />
                      </div>
                      
                    </td>
                    <td class="w-[150px]">
                      <div class="m-2">
                        <input type="text" v-model="item.berat" class="rounded w-full h-[40px]  border border-[#e8e8e8]" />
                      </div>
                      
                    </td>
                    <td class="custom-td-right w-[60px]">
                        <button
                        type="button"
                        v-if="checkingLastRow(index)"
                        @click="addRow"
                        class="inline-flex items-center gap-x-2 text-lg ms-2 font-semibold text-black hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none"
                        >
                            <i class="bi bi-plus-circle-fill"></i>
                        </button>
                        <button type="button" v-if="!checkingLastRow(index)"   @click="openModalDelete(index)" class="inline-flex items-center gap-x-2 text-lg font-semibold ms-2 text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                            <i class="bi bi-trash"></i>
                        </button>
                        <!-- <button @click="addRow" class="mt-4 p-2 bg-blue-500 text-white rounded-md">Add New Row</button> -->

                        <!-- <button type="button"  @click="openModalDelete(index)" class="inline-flex items-center gap-x-2 text-lg font-semibold text-black hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                                        <i class="bi bi-plus-circle-fill"></i>
                                    </button> -->
                    </td>
                    <!-- <td>{{ item.position }}</td>
                              <td>{{ item.office }}</td>
                              <td>{{ item.age }}</td> -->
                  </tr>
              </tbody>
          </table>
        </div>
        
        
  </div>
      
      
  
  <div class="absolute bottom-4 right-6">
    <button @click="clearLocalStorage"
      class="font-redHat text-base text-white h-[40px] w-[243px] bg-blue-500 rounded-md bg-red-600"
    >
      Selesai Live
    </button>
  </div>



   <!-- modal delete -->
   <div
      v-if="isModalOpen"
      class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <!-- Modal content -->
      <div
        class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full"
        @click.stop>
        <div class="flex justify-between items-center mb-4">
          <h2 class="text-xl font-semibold">Delete Produk</h2>
          <button
            @click="closeModal"
            class="text-red-600 hover:text-gray-900">
            ✕
          </button>
        </div>
        <p class="text-gray-700 mb-10">
          Are you sure wants to delete this {{ currentDeleteIndex + 1 }} ?
        </p>

        <div class="space-y-3">
            <div aria-hidden="true" class="border-t px-2"></div>
            <div class="button-group flex justify-end">
                <button
                  @click="closeModal"
                  class="bg-gray-400 text-white font-bold py-2 px-4 rounded hover:bg-gray-700 me-3">
                  Close
                </button>
                <button
                  @click="confirmDelete"
                  class="bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700">
                  Delete
                </button>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    NavBar,
    Multiselect
  },

  data() {
    return {
      
        records: [
            {
            ig: '',
            kode_produk: '',
            warna: '',
            size: '',
            qty: '',
            harga_jual: '',
            berat: '',
            isAdded: false // New row starts with "Add Row" button
            }
        ],

        value: {name: '@IgUsername'},
        options: [
          {name: '@IgUsername'},
          {name: '@IgUsername'},
          {name: '@IgUsername' },
          {name: '@IgUsername'},
          {name: '@IgUsername'}
        ],
        value2: {name: '#1234AAA'},
        options2: [
          {name: '#1234AAA'},
          {name: '#1234AAA'},
          {name: '#1234AAA' },
          {name: '#1234AAA'},
          {name: '#1234AAA'}
        ],
        value3: {name: 'Hitam'},
        options3: [
          {name: 'Merah'},
          {name: 'Biru'},
          {name: 'Hijau' },
          {name: 'Abu-abu'},
          {name: 'Putih'},
          {name: 'Hitam'},
        ],
        value4: {name: 'S'},
        options4: [
          {name: 'M'},
          {name: 'L'},
          {name: 'XL' },
          {name: 'XXL'},
          {name: 'XXXL'}
        ],
        

        isModalOpen: false,
        currentDeleteIndex: null,
    }
  },

  watch: {
    records: {
      handler(newliveIg) {
        // Save records to localStorage
        localStorage.setItem('records', JSON.stringify(newliveIg));
      },
      deep: true // Use deep watcher since records is an array of objects
    },
    
  },


  mounted() {
      // this.$root.initializeDataTable(); 
    
    // Load from localStorage when the component is mounted
    if (localStorage.getItem('records')) {
      this.records = JSON.parse(localStorage.getItem('records'));
    }

  },

  created() {
    // Load from localStorage when the component is created
    const savedLiveIg = localStorage.getItem('records');
    if (savedLiveIg) {
      this.records = JSON.parse(savedLiveIg); // Load saved data into records
      console.log('Stored records:', JSON.parse(savedLiveIg));
    }else {
      console.log('No records found in localStorage.');
    }
  },

  methods: {
      nameWithLang ({name}) {
        return `${name}`
      },

      addRow() {
          // Mark the row as "Added"
          //   this.record[index].isAdded = true

          // Add a new row with a unique id
          this.records.push({
              ig: '',
              kode_produk: '',
              warna: '',
              size: '',
              qty: '',
              harga_jual: '',
              berat: '',
              isAdded: false
          })
      },

      checkingLastRow(index) {
          if(index == this.records.length - 1) {
              // this.records[index].isAdded = true;
              return true;

                  // this.records.splice(index + 1, 0, );
          }else {
              // this.records[index].isAdded = false;
              return false;

          }
      },

      clearLocalStorage() {
        // Clear the "records" key from localStorage
        localStorage.removeItem('records');
        // Optionally reset the records array to its initial state
        this.records = [
          {
            ig: '',
            kode_produk: '',
            warna: '',
            size: '',
            qty: '',
            harga_jual: '',
            deposit: '',
            isAdded: false
          }
        ];

        this.$router.push({ name: 'rekapliveig' }); //turn off if in production
      },

      openModalDelete(index) {
          this.currentDeleteIndex = index;

          this.isModalOpen = true;
      },
      closeModal() {
          this.isModalOpen = false;
      },
      confirmDelete() {
          // Handle the actual deletion here
          if (this.currentDeleteIndex !== null) {
              this.records.splice(this.currentDeleteIndex, 1);  // Delete the item
              this.closeModal();  // Close the modal after deletion
          }
      },
  }

}
</script>

<style scoped>
/* Adjust positioning and z-index for the multiselect dropdown */
.multiselect__content {
  position: absolute;
  z-index: 9999;
  top: 100%;
}

.multiselect-full-width .multiselect__select {
  width: 100%;
}

</style>