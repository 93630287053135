<template>
    <!-- <h1>navbar</h1> -->
    <header class="sticky inset-0 z-50  bg-[#5FBAE6] backdrop-blur-lg border-b border-b-1 border-[#F5CA40]">
        <nav class="mx-6 flex max-w-8xl gap-8 px-2 transition-all duration-200 ease-in-out lg:px-3 py-2">
            <div class="relative flex items-center ">
                <a href="javascript.void(0)" class="mr-8">
                    <!-- <img  src="https://www.svgrepo.com/show/499831/target.svg" loading="lazy" style="color:transparent" width="32" height="32"></a> -->
                    <img  src="@/assets/img/logo-hwang.png" loading="lazy" style="color:transparent" width="84" height="84">
                </a>
            </div>
            <ul  class="hidden md:flex items-center justify-center gap-6">
                <li class="pt-1.5 font-redHat font-bold text-lg text-black hover:text-white">
                    <RouterLink to="dashboard">Dashboard</RouterLink>
                </li>
            
                <li class="relative group pt-1.5 font-bold text-black font-redHat text-lg">
                    <a href="javascript:void(0)" class="flex items-center space-x-1" @click="openMenu = !openMenu">
                        <span>Master Data</span>
                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                    </a>
                    <!-- Dropdown Menu -->
                    <ul v-if="openMenu" class="absolute bg-[#F1EDEA] shadow-lg mt-2 rounded-sm w-48 space-y-2 p-1">
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium">
                            <a href="admin">Admin</a>
                        </li>
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium">
                            <a href="lokasipo">Lokasi PO</a>
                        </li>
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium">
                            <a href="kategoriproduk">Kategori Produk</a>
                        </li>
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium">
                            <a href="jenispenjualan">Jenis Penjualan</a>
                        </li>
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium">
                            <a href="supplier">Supplier</a>
                        </li>
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium">
                            <a href="statuspesanan">Status Pesanan</a>
                        </li>
                        <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 font-medium">
                            <a href="po">PO</a>
                        </li>
                    </ul>
                </li>
                
                <li class="pt-1.5 font-bold font-redHat text-lg text-black hover:text-white">
                    <a href="produk">Produk</a>
                </li>
                
                <li class="pt-1.5 font-bold font-redHat text-lg text-black hover:text-white">
                    <a href="customer">Customer</a>
                </li>
            </ul>

            <div class="flex-grow"></div>
            <div class="items-center justify-center gap-6 md:flex">
                <a href="#" class="font-dm text-xl font-bold text-slate-900">
                    <i class="bi bi-bell"></i>
                </a>
                <a href="#"
                    class="px-3 py-1.5 font-dm text-xl font-bold text-black shadow-sm transition-transform duration-200 ease-in-out hover:scale-[1.03]">
                    <i class="bi bi-gear"></i>
                </a>
                <div class="relative inline-block text-left"  >

                    <button @click="profileDropdown = !profileDropdown" id="dropdownHoverButton" class="inline-flex items-center space-y-2 gap-2 font-redHat text-lg font-bold">
                        <!-- profile photo -->
                        <img src="@/assets/img/profile-dummy.png" alt="Profile Photo" class="w-12 h-12 rounded-full mr-2" />
    
                        <!-- username -->
                        <p>Admin</p>
    
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="h-4 w-4 ml-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                    </button>

                    <!-- dropdown --> <!-- aktifin jika digunakan-->
                    <ul v-if="profileDropdown" class="absolute  bg-[#F1EDEA] shadow-lg mt-2 rounded-sm w-40 space-y-2 p-1">
                        <!-- <li class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 border-b">
                            <a href="javascript.void(0)">Profile</a>
                        </li> -->
                        <!-- <li class="block px-4 py-2 text-red-500 font-redHat text-base hover:bg-[#5FBAE6]/80">
                            <a href=""  @click="handleLogout">Logout</a>
                        </li> -->
                    </ul>
    

    
                    

                    

                    
                </div>
            </div>
            <!-- <div class="relative flex items-center justify-center md:hidden">
                <button type="button">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="h-6 w-auto text-slate-900"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path></svg>
                </button>
            </div> -->
        </nav>
    </header>




</template>

<style scoped>

</style>

<script>
// import { ref } from 'vue';
// import axios from 'axios';
// import { goPage } from "@/assets/js/function";
// import Cookies from "js-cookie";




export default {
  name: 'NavBar',

  data(){
    return {
        openMenu: false,
        profileDropdown: false,

    };
  },

  method: {
    // logout() {
    //   // Clear token from cookies or localStorage
    //   Cookies.expire('token');  
      
    //   // Clear Axios token
    //   delete axios.defaults.headers.common['Authorization'];
      
    //   // Redirect user to the login page
    //   this.$router.push('signin');
    // }
    // handleLogout() {
    //   //logOut();  // Call the logOut function
    //     Cookies.set("token", "");
    //     goPage("signin");
    // }
  },

  


};
</script>